import React, { Component } from 'react';
import { AuthUserContext } from '../../../utils/Session';
import PasswordForget from '../../molecules/PasswordForget/PasswordForget';
import PasswordChange from '../../molecules/PasswordChange/PasswordChange';
import LoginManagement from './molecules/LoginManagement/LoginManagement';
import { withFirebase } from '../../../utils/Firebase';
import { navigate } from 'gatsby';
import { SIGN_IN } from '../../../constants/routes';

const Account = (props) => {
  return (
    <div className="settings container">
      <AuthUserContext.Consumer>
        {(authUser) => (
          <div>
            <h1>Обліковий запис: {authUser.email}</h1>
            <a
              className="signout-btn"
              onClick={
                props.firebase
                  ? () => {
                      props.firebase.doSignOut();
                      navigate(SIGN_IN);
                    }
                  : () => {}
              }
            >
              Вийти
            </a>
            <PasswordForget className="settings__password-forget" />
            <PasswordChange className="settings__password-change" />
            <LoginManagement
              className="settings__login-management"
              authUser={authUser}
            />
          </div>
        )}
      </AuthUserContext.Consumer>
    </div>
  );
};

export default withFirebase(Account);
